import React, { useCallback, useContext, useEffect } from "react"
import { Link } from "gatsby"
import styles from "../styles/voltshare-download.module.scss"
import { Helper } from "../utils/helper"
import { VoltshareVersionContext } from "../contexts/voltshare-version.context"
import withAppContext from "../contexts/app.context"
import Layout from "../components/layout/layout"

const VoltShareDownloadPage = () => {
  const { getVoltshareVersion } = useContext(VoltshareVersionContext)

  const downloadVoltshare = useCallback(() => {
    const macOSLink =
      "https://downloads.volterra.io/releases/voltshare/mac/VoltShare-VOLT_SHARE_DYNAMIC_VERSION.dmg"
    const linuxLink =
      "https://downloads.volterra.io/releases/voltshare/linux/VoltShare-VOLT_SHARE_DYNAMIC_VERSION.AppImage"
    const windowsLink =
      "https://downloads.volterra.io/releases/voltshare/windows/VoltShare-VOLT_SHARE_DYNAMIC_VERSION.exe"

    const link = Helper.chooseLinkFromOS(windowsLink, macOSLink, linuxLink)

    getVoltshareVersion()
      .then(version => {
        window.location.href = Helper.replaceVoltShareVersion(link, version)
      })
      .catch(defaultVersion => {
        window.location.href = Helper.replaceVoltShareVersion(
          link,
          defaultVersion
        )
      })
  }, [getVoltshareVersion])

  useEffect(() => {
    downloadVoltshare()
  }, [])

  return (
    <Layout>
      <div className={`container ${styles.pageContainer}`}>
        <p className={styles.title}>Your download will begin in a moment.</p>
        <p className={styles.description}>
          If it doesn't,{" "}
          <a
            aria-label="Restart download"
            href=""
            onClick={event => {
              event.preventDefault()
              window.location.reload()
            }}
            className={styles.description__link}>
            restart the download
          </a>
          .
        </p>
        <Link
          to="/"
          aria-label="Homepage"
          className={styles.description__btnLink}>
          Homepage
        </Link>
      </div>
    </Layout>
  )
}

export default withAppContext(VoltShareDownloadPage)
